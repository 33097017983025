export const headers = {
    'Appointments': [
        'Company',
        'Stage',
        'Appointment date & time',
        'Confirmed',
        'Name',
        'Contact',
        'Customer company',
        'Address',
        'Unit #',
        'Postal Code',
        'Zone',
        'AG no.',
        'Agreement date',
        'Grand Total',
        'Payments',
        'Outstanding',
        'Install / Maintenance',
        'Creator',
        'ID Name',
        'ID Commission',
        'Sales 1 Name',
        'Sales 1 Commission',
        'Sales 2 Name',
        'Sales 2 Commission',
    ],
    'Appointments--Sales': [
        'Company',
        'Stage',
        'Appointment date & time',
        'Confirmed',
        'Name',
        'Contact',
        'Customer company',
        'Address',
        'Unit #',
        'Postal Code',
        'Zone',
        '2 Name',
        '2 Contact',
        '2 Relationship',
        'Products',
        'Total Price',
        'Discount',
        'Subtotal',
        'GST',
        'Grand Total',
        'ID Name',
        'ID Commission',
        'Sales 1 Name',
        'Sales 1 Commission',
        'Sales 2 Name',
        'Sales 2 Commission',
    ],
    'Products': [
        'Priority',
        'Model',
        'Color',
        'Price',
        'Commission (sales)',
        'Commission (referrer)',
        'Commission (install)'
    ],
    'Maintenance': [
        'Appointment name',
        'Job',
        'Date & Time',
        'Confirmed',
        'Address & Zone',
        'Amount to collect',
        'Mode',
        'Status',
        'Assign to'
    ],
    'Payments': [
        'ID',
        'Payment Mode',
        'Payment Method',
        'Amount',
        'Date',
        'Bank',
        'Detail',
        'Payment Status'
    ],
    'Leads': [
        'Source',
        'Name',
        'Contact',
        'No. of pax',
        'Assign to',
        'Date & Time',
    ],
}

export const listLabels = {
    'Remarks': [
        'Date',
        'Text',
        'Creator',
    ],
    'Payments': [
        'ID',
        'Payment Mode',
        'Payment Method',
        'Amount',
        'Date',
        'Bank',
        'Detail',
        'Payment Status'
    ],
    'Maintenance': [
        'Job',
        'Unit',
        'Date & Time',
        'Confirmed',
        'Amount to collect',
        'Mode',
        'Status'
    ]
}